/* ROW HOVER STYLES */
.hoverable-row {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

/* Additional recommended styling for table rows */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

table,
s th,
td {
  /* border: 1px solid black; */
  border-collapse: collapse;
  padding: 5px;
}

th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}
s table {
  width: 100%;
  height: 100%;
}

input,
select,
button {
  background-color: #f2f2f2;
  width: auto;
  margin: 5px;
}

select,
button {
  cursor: pointer;
}

tr.clickable {
  cursor: pointer;
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.rDiv {
  display: flex;
  flex-direction: row;
  margin: 5px;
  height: 50px;
}

.cDiv {
  display: flex;
  flex-direction: column;
}

.scrollableRDiv {
  display: flex;
  flex-direction: row;
  height: calc(100% - 90px); /* Set this to fit within the parent container */
  overflow: hidden; /* Prevents .scrollableRDiv from scrolling */
  border: 1px solid black;
}

.scrollableCDiv {
  flex: 1; /* Allows equal distribution of space */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  max-height: calc(100vh - 200px);
  border: "1px solid #ddd";
}

.largeBox {
  height: calc(100vh - 100px); /* Defined height relative to viewport */
  width: 100vw; /* Defined width */
  bottom: 0;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid black;
  z-index: 3000;
}

/* Form styling to create a scrollable area above the footer */
.assemblyForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.footerActions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f9f9f9;
  padding-right: 20px;
  box-shadow: 0 -2px 2px -1px rgba(0, 0, 0, 0.4);
  z-index: 4;
}

/* Container for input fields */
.formInputs {
  padding: 10px;
}

.TakeoffPalletteContainer {
  height: calc(100vh - 100px);
  width: 30vw; /* Fixed width */
  position: fixed;
  top: 100px;
  right: 0;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-top: #8c9da0 1px solid;
  border-radius: 8px;
}

.TakeoffPalletteParentBox {
  flex-grow: 1;
  background-color: #8c9da0;
  border: 1px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.TakeoffPalletteChildBox {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 99%;
  border: 1px solid black;
}

.ChatWindowContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ChatMessagesContainer {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 10px;
  margin-bottom: 170px;
}

.ChatInputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border-top: 1px solid black;
  padding: 8px;
  position: absolute;
  bottom: 0;
}

.TabPanelContainer {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.scrollableTableContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 5px;
  margin-right: 5px;
}

.document-border {
  border: 2px solid rgb(0, 38, 255); /* Adjust the border color and width as needed */
  display: inline-block; /* Ensures the border matches the document size */
}

.attribute-values-container {
  max-height: 380px; /* You can adjust this height based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 2px black solid; /* Optional: Add a border for visual distinction */
  padding: 10px; /* Optional: Add padding for better spacing */
}

/* Add this to your App.css or another appropriate CSS file */
.upload-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it appears on top */
  background-color: white;
  border: 1px solid black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 80vw;
  padding: 20px;
}

.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
  z-index: 999; /* Under the modal but above other content */
}

@keyframes pulseLeftToRight {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

.glow-progress {
  background-color: rgba(0, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.glow-progress::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 200%; /* Extended width to create a smooth transition */
  background: linear-gradient(
    90deg,
    rgba(60, 255, 0, 0.1) 0%,
    rgba(0, 255, 255, 0.8) 50%,
    rgba(60, 255, 255, 0.1) 100%
  );
  animation: pulseLeftToRight 3s infinite;
}
